<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'600px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceInfoForm"
        :rules="rules"
        ref="form"
        :label-width="'95px'"
        label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }"
        size="large"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="name"
              :label="$t('add.产品名称') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.name"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="productCategoryId"
              :label="$t('add.产品类别') + '：'"
            >
              <el-select
                multiple
                filterable
                v-model="deviceInfoForm.productCategoryId"
                :placeholder="$t('global.请选择')"
              >
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="code"
              :label="$t('add.编码') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.code"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="scale"
              :label="$t('add.规格') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.scale"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="unit"
              :label="$t('add.单位') + '：'"
            >
              <el-input
                v-model="deviceInfoForm.unit"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="remark"
              :label="$t('add.备注') + '：'"
            >
              <el-input
                type="textarea"
                v-model="deviceInfoForm.remark"
                :placeholder="$t('global.请输入')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('add.部门')" prop="orgId">
              <el-cascader
                      v-model="deviceInfoForm.orgId"
                      :options="deptTree"
                      :props="{ label:'orgName', value:'id',children:'childrenList',checkStrictly: true ,'emitPath':false }"
                      clearable
                      :placeholder="$t('global.请选择')"
                      @change="handleChangeDept"
                      :show-all-levels="false"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('add.负责人')" prop="principalId" >
              <el-select
                      v-model="deviceInfoForm.principalId"
                      :placeholder="$t('global.请选择')"
              >
                <el-option
                        v-for="item in userEnums"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button type="primary" :loading="posting" @click="close()">
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close">{{ $t("global.cancel") }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import tool from '@/tool'
import base from '@/templates/add'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: '',
      dialogFormVisible2: false,
      dialogFormVisible: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      isModelInfo: false,
      deviceInfoForm: {
        name: '',
        productCategoryId: [],
        code: '',
        scale: '',
        unit: '',
        remark: '',
        principalId: ''
      },
      rules: {
        name: [
          { required: true, message: this.$t('add.nameMsg'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        unit: [
          { required: true, message: this.$t('add.unitMsg'), trigger: ['blur', 'change'] },
          { pattern: /\S+/, message: this.$t('global.emptyMsg') }
        ],
        orgId: [
          { required: true, message: this.$t('global.请选择') , trigger: ['change'] }
        ],
        principalId: [
          { required: true, message: this.$t('global.请选择') , trigger: ['change'] }
        ]
      },
      deptTree: [],
      typeList: [],
      userEnums: []
    }
  },
  created () {
    api.auth.queryOptionTypeList({ type: 'productCategory' }).then(res => {
      this.typeList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    })
    api.organization.queryOrgTree({ }).then(res => {
      var tree = tool.treearr(res.data, true)
      this.deptTree = tree
    })
  },
  methods: {
    show () {
      this.dialogFormVisible2 = true
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.isModelEdit = false
      this.isModelAdd = false
      this.isModelInfo = false
      this.dialogFormVisible = false
      this.resetForm()
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false
      })
    },
    add () {
      console.log(this.$t('add'))
      this.titleTable = this.$t('add.新增项目')
      this.isModelAdd = true
      this.show()
    },
    edit (data) {
      this.titleTable = this.$t('add.编辑项目')
      this.isModelEdit = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    view (data) {
      this.titleTable = this.$t('add.项目详情')
      this.isModelInfo = true
      this.deviceInfoForm = { ...data }
      this.show()
    },
    // 重制
    resetForm () {
      this.deviceInfoForm = {}
      this.$refs.form.resetFields()
    },
    handleChangeDept (orgs) {
      var orgId = orgs
     //
      api.user.queryUserList({ orgId: orgId }).then(res => {
        this.deviceInfoForm.principalId = ''
        this.userEnums = res.data.map(ele => ({ label: ele.userName, value: ele.id }))
      })
    },
    // 提交
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.posting = true
          const temp = this.deviceInfoForm.productCategoryId.join()
          const query = {
            ...this.deviceInfoForm,
            id: this.deviceInfoForm.id,
            productCategoryId: temp ? temp + ',' : temp
          }
          try {
            await api.productMerge[this.isModelAdd ? 'save' : 'update'](query)
            this.posting = false
            this.$message.success(this.$t('global.submitOk'))
            this.close()
            this.$parent.onSubmit()
          } catch (error) {
            this.posting = false
          }
        }
      })
    }
  }
}
</script>
