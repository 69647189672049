<script>
import api from '@/api'
import tool from '@/tool'
import base from '@/templates/list'
import MyDialog from './dialog'
export default {
  directives: {},
  filters: {},
  components: {
    MyDialog
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      tableAction: {
        add: { show: tool.checkButton('product-merge-add'), title: this.$t('global.add') },
        remove: { show: tool.checkButton('product-merge-remove'), title: this.$t('global.remove') },
        edit: { show: tool.checkButton('product-merge-edit'), title: this.$t('global.edit') },
        view: { show: tool.checkButton('product-merge-view'), title: this.$t('global.details') }
      },
      showList: [
        {
          copName: 'el-input',
          placeholder: 'list.产品编号/名称',
          key: 'query',
          name: '',
          attrs: ['placeholder']
        },
        {
          copName: 'el-select',
          key: 'productCategoryId',
          type: '',
          attrs: ['placeholder', 'options'],
          placeholder: 'list.类别',
          options: []
        },
        {
          copName: 'el-date-picker',
          placeholder: 'list.更新时间',
          key: 'updateTime',
          dates: [],
          attrs: ['copType', 'valueFormat', 'placeholder'],
          copType: 'date',
          valueFormat: 'yyyy-MM-dd'
        }
      ],
      tableTile: [
        {
          key: '0',
          columnLabel: 'global.序号',
          width: '80',
          type: 'index'
        },
        {
          key: 'name',
          columnLabel: 'list.名称',
          prop: 'name',
          width: ''
        },
        {
          key: 'productCategoryName',
          columnLabel: 'list.类别',
          prop: 'productCategoryName',
          width: ''
        },
        {
          key: 'scale',
          columnLabel: 'list.规格',
          prop: 'scale',
          width: ''
        },
        {
          key: 'unit',
          columnLabel: 'list.单位',
          prop: 'unit',
          width: ''
        },
        {
          key: 'remark',
          columnLabel: 'global.备注',
          prop: 'remark',
          width: ''
        },
        {
          key: 'updateTime',
          columnLabel: 'list.修改日期',
          prop: 'updateTime',
          width: '',
          formatter: (row, column, cellValue, index) => this.$moment(cellValue).format('YYYY-MM-DD HH:mm:ss')
        }
      ],
      typeList: []
    }
  },
  created () {
    api.auth.queryOptionTypeList({ type: 'productCategory' }).then(res => {
      this.showList[1].options = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    })
  },
  methods: {
    init () {
      this.toPage(1)
    },
    onSubmit () {
      this.loading = true

      const query = {
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          ...this.formInline
        }
      }
      api.productMerge.query(query)
        .then((res) => {
          if (res.data && res.data.records) {
            this.tableData = res.data.records
            this.formInline.page.total = res.data.total
          }
          this.loading = false
        })
    },
    add () {
      this.$refs.myDialog.add()
    },
    remove (id) {
      this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      }).then(() => {
        api.productMerge.remove({ id: id })
          .then(() => {

            this.toPage(1)
          })
      })
    },
    edit (id) {
      api.productMerge.findById({ id }).then(res => {
        const temp = res.data.productCategoryId ? res.data.productCategoryId.split(',') : ''
        if (Array.isArray(temp) && temp[temp.length - 1] === '') {
          temp.splice(temp.length - 1, 1)
        }
        this.$refs.myDialog.edit({
          ...(res.data || {}),
          productCategoryId: temp
        })
      })
    },
    view (id) {
      api.productMerge.findById({ id }).then(res => {
        const temp = res.data.productCategoryId ? res.data.productCategoryId.split(',') : ''
        if (Array.isArray(temp) && temp[temp.length - 1] === '') {
          temp.splice(temp.length - 1, 1)
        }
        this.$refs.myDialog.view({
          ...(res.data || {}),
          productCategoryId: temp
        })
      })
    }
  }
}
</script>
